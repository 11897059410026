/** @jsxRuntime classic */
/** @jsx jsx */
import { Box, Divider, Flex, Grid, Heading, jsx } from "theme-ui"
import RetailerLocator from "components/Locator/RetailerLocator"

interface ReadyToBuyCTAProps {
  //enables sx prop to be passed down from parent
  className?: string
}

const ReadyToBuyCTA = ({ className }: ReadyToBuyCTAProps) => {
  return (
    <Box as="aside" sx={{ my: [12, null, null, 24] }}>
      <Grid
        sx={{
          gridTemplateColumns:
            "[outer-left] minmax(16px, 1fr) [grid-left] minmax(0, 1188px) [grid-right] minmax(16px, 1fr) [outer-right]",
          gap: 0,
        }}
      >
        <Flex
          sx={{
            gridColumn: "outer-left / grid-right",
            alignItems: ["stretch", null, null, "center"],
            justifyContent: "flex-end",
            flexDirection: ["column", null, null, "row"],
            gap: 14,
          }}
        >
          <Flex
            sx={{
              width: "100%",
              alignItems: "center",
              mr: 8,
              minWidth: "min-content",
            }}
          >
            <Divider sx={{ flex: "0 1 auto", mr: 14 }} />
            <Heading as="h1" variant="hero" sx={{ flex: "1 0 auto" }}>
              Ready to buy?
            </Heading>
          </Flex>
          <Box
            sx={{
              flex: ["1 100%", null, null, "1 0 510px", null],
              pl: [4, null, null, 0, 0],
              "@media screen and (max-width > 1220px": {
                flex: "1 0 610px",
              },
            }}
          >
            <RetailerLocator sx={{ width: "100%" }} />
          </Box>
        </Flex>
      </Grid>
    </Box>
  )
}

export default ReadyToBuyCTA
