/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Box, Container, Themed } from "theme-ui"
import React from "react"

import AguilaButton from "components/AguilaButton"
import LineAnimation from "components/common/LineAnimation"
import MdContent from "components/common/MdContent"
import SectionHeading from "components/SectionHeading"

interface LEGovServicesBlockMapperProps {
  block: any
}

const LEGovServicesBlockMapper = ({ block }: LEGovServicesBlockMapperProps) => {
  switch (block.strapi_component) {
    case "le-government-services.section-with-list-and-content":
      return (
        <Box as="section" sx={{ position: "relative", pb: 44 }}>
          <LineAnimation sx={{ height: "50%", top: "45%" }} />
          <Box sx={{ mt: 0, position: "relative", zIndex: 1 }}>
            <SectionHeading>{block.title}</SectionHeading>
            <Container sx={{ pb: [14, null, 28] }}>
              <Themed.ul
                sx={{
                  pl: [4, null, 28],
                  "> li": { variant: "text.title" },
                }}
              >
                {block.list_items.map((item: any, index: number) => (
                  <li key={index}>{item.list_item_text}</li>
                ))}
              </Themed.ul>
            </Container>

            <SectionHeading indented>{block.subtitle}</SectionHeading>
            <Container sx={{ pb: [14, null, 28] }}>
              <Box sx={{ maxWidth: "600px", pl: [0, null, 24] }}>
                <MdContent source={block.content} />

                <AguilaButton
                  url={block.link_button.link_button_url}
                  text={block.link_button.link_button_text}
                  sx={{ mt: 8 }}
                />
              </Box>
            </Container>
          </Box>
        </Box>
      )
    default:
      return <></>
  }
}

export default LEGovServicesBlockMapper
