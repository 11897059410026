/** @jsxRuntime classic */
/** @jsx jsx */
import { IGatsbyImageData } from "gatsby-plugin-image"
import { graphql, PageProps } from "gatsby"
import { jsx, Box } from "theme-ui"

import type { LeGovernmentPageQuery } from "../../graphql-types"
import BackgroundSVG from "../images/icons/background-light.svg"

import LEGovServicesBlockMapper from "components/block-mappers/LEGovServicesBlockMapper"
import Layout from "components/Layout"
import PageHero from "components/PageHero"
import ReadyToBuyCTA from "components/ReadyToBuyCTA"

export const query = graphql`
  query LEGovernmentPage {
    strapiLawEnforcement {
      blocks
      seo {
        metaTitle
        metaDescription
        shareImage {
          localFile {
            publicURL
          }
        }
      }
      Hero {
        hero_links {
          link_button_url
          link_button_text
        }
        title
        content
        has_lines
        has_locator
        image {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        image_position
        subtitle
      }
    }
  }
`

interface LEGovernmentPage extends PageProps {
  data: LeGovernmentPageQuery
}

const LEGovernmentPage = ({ data, ...props }: LEGovernmentPage) => {
  return (
    <Layout seo={data.strapiLawEnforcement?.seo} location={props.location}>
      <Box sx={{ position: "relative" }}>
        <BackgroundSVG
          sx={{
            position: "absolute",
            zIndex: -1,
            transformOrigin: "center",
            bottom: 0,
            right: 0,
            width: "auto",
            height: "100%",
            transform: "translate(30%, 0)",
            "& > g": {
              opacity: 0.2,
            },
            "& *": {
              fill: "#d6d1c4cf",
            },
          }}
        />
        <PageHero
          title={data.strapiLawEnforcement?.Hero?.title as string}
          subtitle={data.strapiLawEnforcement?.Hero?.subtitle as string}
          content={data.strapiLawEnforcement?.Hero?.content as string}
          hasLines={data.strapiLawEnforcement?.Hero?.has_lines as boolean}
          hasLocatorCTA={
            data.strapiLawEnforcement?.Hero?.has_locator as boolean
          }
          imagePosition={
            data.strapiLawEnforcement?.Hero?.image_position as string
          }
          image={
            data.strapiLawEnforcement?.Hero?.image?.localFile?.childImageSharp
              ?.gatsbyImageData as IGatsbyImageData
          }
          imageAlt={data.strapiLawEnforcement?.Hero?.image?.alternativeText}
          buttonOneUrl={
            data.strapiLawEnforcement?.Hero?.hero_links?.[0]
              ?.link_button_url as string
          }
          buttonOneText={
            data.strapiLawEnforcement?.Hero?.hero_links?.[0]
              ?.link_button_text as string
          }
          buttonTwoUrl={
            data.strapiLawEnforcement?.Hero?.hero_links?.[1]
              ?.link_button_url as string
          }
          buttonTwoText={
            data.strapiLawEnforcement?.Hero?.hero_links?.[1]
              ?.link_button_text as string
          }
          sx={{
            ".titlebox": {
              maxWidth: ["70%", null, "610px"],
              mr: [0, null, 16],
            },
          }}
        />

        {data.strapiLawEnforcement?.blocks.map((block: any, index: number) => (
          <LEGovServicesBlockMapper block={block} key={index} />
        ))}

        <ReadyToBuyCTA />
      </Box>
    </Layout>
  )
}

export default LEGovernmentPage
